import React, { useEffect } from 'react';
//import AnnouncmentBanner from '../Announcment'
import { API } from 'aws-amplify';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import TopFormSearch from '../TopFormSearch';
import { TopFormSearchOption } from '../TopFormSearch';
import DropDown, { DropDownOption, DropDownState } from '../dropdown';
import { Toll } from '../../_models/Toll';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../../common/handleApiErrors';
import DateRangePicker from '../date-range-picker.component';
import DealershipSelect from '../dealership-select.component';
// import moment from 'moment';
import moment, { tz } from "moment-timezone";
import ReactTable, { Column, SortingRule } from 'react-table';
import { getAPIName } from '../../config';
import DatePickerComp from '../../components/date-picker.component'
import { navigate } from '@reach/router';
import { statusOptionsDict } from './Invoices';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes,
  faSortNumericDown
} from '@fortawesome/free-solid-svg-icons';
import RadioFormSearch from '../RadioForm';
import { RadioSearchOptions } from '../RadioForm';
library.add(
  faEdit as IconDefinition,
  faCalendarAlt as IconDefinition,
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faAngleDoubleLeft as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faCheck as IconDefinition,
  faTimes as IconDefinition
);

const DEFAULT_PAGE_SIZE: number = 10;
const searchOptions: TopFormSearchOption[] = [
  { label: 'License Plate', value: 'license-plate' },
  { label: 'VIN', value: 'vin' },
];

const timestampTypeSearchOption: RadioSearchOptions[] = [
  { label: 'Transaction Time', value: 'transaction_timestamp' },
  { label: 'Inserted Time', value: 'inserted_timestamp  ' },
]

const statusOptions = [
  { name: 'Tolls', value: '1' },
  { name: 'Parking', value: '2' },
  { name: 'Violation', value: '3' }
];

interface PaginationState {
  readonly page: number;
  readonly pageSize: number;
  readonly sortingRules: SortingRule[];
}

interface TollsState {
  readonly dealershipId: number;
  readonly endDate?: Date;
  readonly searchInput: string;
  readonly searchType: TopFormSearchOption;
  readonly startDate?: Date;
  readonly timeSearchType?: RadioSearchOptions;
}

const defaultPaginationState: PaginationState = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sortingRules: [{
    id: 'id',
    desc: false,
  }],
};

const defaultState: TollsState = {
  dealershipId: 0,
  searchInput: '',
  searchType: searchOptions[0],
  timeSearchType: timestampTypeSearchOption[0]
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    paper: {
      padding: 10
    },
    spacedButton: {
      marginRight: '2rem',
    },
  })
);

const tollStatusMap: Record<number, string> = {
  35: 'Duplicate',
  34: 'Potential Duplicate',
  3: 'Waived'
};
export default function Tolls() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [csvIsLoading, setCsvIsLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [paginationState, setPaginationState] = React.useState<PaginationState>(defaultPaginationState);
  const [state, setState] = React.useState<TollsState>(defaultState);

  const [dropdownState, setDropDownState] = React.useState<DropDownState>({
    statusSelection: undefined,
  });
  // fetch stuff //////////////////////////////////////////////////////////////
  // tolls
  const [[tolls, totalTollCount, tollSum], setTolls] = React.useState<[Toll[], number, number]>([[], 0, 0]);
  const searchTolls = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getTolls(true, {});
    setTolls([response.body, response.totalRecordCount, parseFloat(response.tollSum)]);
    setIsLoading(false);
  }
  useEffect(() => { searchTolls(); }, [paginationState.page, paginationState.pageSize, paginationState.sortingRules]);

  // handlers /////////////////////////////////////////////////////////////////
  const handleCsvButton = async (): Promise<void> => {
    setCsvIsLoading(true);
    const init = {
      headers: {
        accept: 'text/csv',
        timezone: moment.tz.guess(),
      },
      responseType: 'text',
    };
    const response = await getTolls(false, init);
    const currentDate = moment().format('YYYYMMDD-hhmmss')
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "TollSummary" + currentDate + fileExtension);
    setCsvIsLoading(false);
  };
  const handleDealershipSelect = (id: number): void => {
    resetPaginationState();
    setState({
      ...state,
      dealershipId: id,
    });
  };
  const handleEndDateChange = (date?: Date | null): void => {
    resetPaginationState();
    setState({
      ...state,
      endDate: date == null ? undefined : date,
    });
  };
  const handleSearch = (): void => {
    searchTolls();
  };
  const handleSearchInputChange = (searchInput: string): void => {
    resetPaginationState();
    setState({
      ...state,
      searchInput: searchInput.toUpperCase(),
    });
  };
  const handleTimeSearchInputChange = (searchInput: RadioSearchOptions): void => {
    resetPaginationState();
    setState({
      ...state,
      timeSearchType: searchInput,
    });
  };
  const handleSearchTypeChange = (searchType: TopFormSearchOption): void => {
    resetPaginationState();
    setState({
      ...state,
      searchType: searchType,
    });
  };
  const handleStartDateChange = (date?: Date | null): void => {
    resetPaginationState();
    setState({
      ...state,
      startDate: date == null ? undefined : date,
    });
  };
  const handleTableFetchData = (state: { page: number; pageSize: number, sorted: SortingRule[] }): void => {
    setPaginationState({
      ...paginationState,
      page: state.page,
      pageSize: state.pageSize,
      sortingRules: state.sorted,
    });
  }

  const resetPaginationState = (): void => {
    setPaginationState({
      ...paginationState,
      page: 0,
    });
  }

  const getTolls = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
    const queries: Array<{ name: string; value: string }> = paginate == false
      ? []
      : [
        { name: 'limit', value: `${paginationState.pageSize}` },
        { name: 'offset', value: `${paginationState.pageSize * paginationState.page}` },
      ];
    if (dropdownState.statusSelection?.value != undefined || dropdownState.statusSelection?.value != null)
      queries.push({ name: 'toll_type_id', value: `${dropdownState.statusSelection?.value}` });
    if (state.dealershipId != 0)
      queries.push({ name: 'dealershipid', value: `${state.dealershipId}` });
    if (state.searchInput !== '') {
      switch (state.searchType.value.toLowerCase()) {
        case 'license-plate':
          queries.push({ name: 'vehicle.licenseplate', value: state.searchInput });
          break;
        case 'vin':
          queries.push({ name: 'vehicle.right-vin', value: state.searchInput });
          break;
      }
    }
    if (state.startDate != null){
      const start_filter = state.timeSearchType?.value == 'transaction_timestamp'  ? 'gte-posted_time' : 'gte-insertedtimestamp'
      queries.push({ name: start_filter, value: state.startDate.toISOString() });
    }
    if (state.endDate != null){
      const end_filter = state.timeSearchType?.value == 'transaction_timestamp'  ? 'lt-posted_time' : 'lt-insertedtimestamp'
      queries.push({ name: end_filter, value: state.endDate.toISOString() });
    }

    // sort
    let i: number = 0;
    for (let sortingRule of paginationState.sortingRules)
      queries.push({ name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`, value: `${sortingRule.id}` });

    let path: string = '/tolls';
    if (queries.length > 0) {
      const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
      path = `${path}?${paramStrings.join("&")}`;
    }

    try {
      return await API.get(getAPIName(), path, apiInit);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      return [[], 0];
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  let pages: number = totalTollCount === 0 || paginationState.pageSize === 0
    ? 1
    : Math.trunc(totalTollCount / paginationState.pageSize)
  if (
    pages > 0
    && totalTollCount > paginationState.pageSize
    && totalTollCount % paginationState.pageSize > 0
  )
    ++pages;

  // Posted time, transaction time, license plate, VIN, location, amount, type (toll or parking), status 
  const columns: Column[] = [
    /*
    {
      accessor: (toll: Toll) => toll.posted_time == null
        ? '—'
        : moment(toll.posted_time).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Posted',
      id: 'posted_time',
    },
    */
    {
      accessor: (toll: Toll) => toll.transaction_time == null
        ? '—'
        : moment(toll.transaction_time).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Transaction',
      id: 'transaction_time',
    },
    {
      accessor: (toll: Toll) => toll.vehicle == null || toll.vehicle.licenseplate == null
        ? '—'
        : toll.vehicle.licenseplate,
      Header: 'License Plate',
      id: 'vehicle.licenseplate',
    },
    {
      accessor: (toll: Toll) => toll.description_exit != null ? toll.description_exit : '—',
      Header: 'Exit Location',
      id: 'description_exit',
    },
    {
      accessor: (toll: Toll) => toll.vehicle == null || toll.vehicle.vin == null
        ? '—'
        : toll.vehicle.vin,
      Header: 'VIN',
      id: 'vehicle.vin',
    },
    {
      accessor: (toll: Toll) => toll.dealership?.cash_rate === true && toll.cash_amount > 0 ? `$${(+toll.cash_amount).toFixed(2)}` : `$${(+toll.amount).toFixed(2)}`,
      Header: 'Amount',
      id: 'amount',
    },
    {
      accessor: (toll: Toll) => toll.admin_fees != null ? `$${(+toll.admin_fees).toFixed(2)}` : '—',
      Header: 'Admin Fees',
      id: 'admin_fees',
    },
    {
      accessor: (toll: Toll) => toll.toll_type != null ? toll.toll_type.description : '—',
      Header: 'Type',
      id: 'toll_type_id',
    },
    {
      accessor: (toll: Toll) => 
        tollStatusMap[toll.toll_status_id] ?? 
        ((toll.invoiceid == null && toll.subscription_invoiceid == null) ? 'Pending' : 'Invoiced'),
      Header: 'Status',
      id: 'toll_status',
    },
    {
      accessor: (toll: Toll) => toll.invoiceid == null ? 'N/A' : <a style={{ textDecoration: 'none' }} target="_blank" href={'https://tollaid-emailtemplates.s3.us-east-2.amazonaws.com/prod/' + toll.invoice_tolls?.bucketpath}>{toll.invoiceid}</a>,
      Header: 'Invoice Id',
      id: 'invoiceid',
    },
    {
      Header: 'Invoice Status',
      Cell: (props: any) => {
        return statusOptionsDict[props.original.invoice_tolls?.status] || '-'
      },
      id: 'invoice.status'
    },
    {
      accessor: (toll: Toll) => toll.subscription_invoiceid == null ? 'N/A' : <a style={{ textDecoration: 'none' }} target="_blank" href={'https://tollaid-emailtemplates.s3.us-east-2.amazonaws.com/prod/' + toll.subscription_invoice_tolls?.bucketpath}>{toll.subscription_invoiceid}</a>,
      Header: 'Subscription Invoice Id',
      id: 'subscription_invoiceid',
    },
    {
      Cell: (props: any) => {
        return statusOptionsDict[props.original.subscription_invoice_tolls?.status] || '-'
      },
      Header: 'Subscription Invoice Status',
      id: 'subscription_invoice.status',
    },
    // {
    //   accessor: (toll: Toll) => toll.contractid == null || toll.contract?.contractnumber == null ? 'N/A' : toll.contract.contractnumber,
    //   Header: 'Contract Number',
    //   id: 'contract.contractnumber',
    // },
    // {
    //   accessor: (toll: Toll) => toll.contractid == null || toll.contract?.ro_number == null ? 'N/A' : toll.contract.ro_number,
    //   Header: 'RO Number',
    //   id: 'contract.ro_number',
    // },
  ];

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h4" align="left">
            Tolls
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.spacedButton}
            onClick={() => navigate('toll/new')}
          >
            Add Charges
          </Button>
          <Button size="small" variant="outlined" onClick={handleCsvButton}>
            {!csvIsLoading ? 'Download Excel' :
              <CircularProgress size={24} />
            }
          </Button>
        </Grid>
      </Grid>
      <div className="TopForm">
        <Divider />
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <DealershipSelect
              dealershipId={state.dealershipId}
              onChangeDealership={handleDealershipSelect}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <DropDown
              onChange={setDropDownState}
              state={dropdownState}
              dropdownOptions={statusOptions}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TopFormSearch
              onSearchInputChange={handleSearchInputChange}
              onSearchTypeChange={handleSearchTypeChange}
              searchInput={state.searchInput}
              searchOptions={[
                { label: 'License Plate', value: 'license-plate' },
                { label: 'VIN', value: 'vin' },
              ]}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <DateRangePicker
              endDate={state.endDate == null ? null : state.endDate}
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              pickTime={true}
              startDate={state.startDate == null ? null : state.startDate}
            />
            <RadioFormSearch
              onSearchTypeChange={handleTimeSearchInputChange}
              radioSearchOptions={timestampTypeSearchOption}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

        </Grid>

      </div>
      {/* Format sum to 2 decimals and add commas */}
      <h3>Toll Total: ${tollSum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
      <Grid container>
        <Grid item xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={tolls}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={defaultPaginationState.sortingRules}
            loading={isLoading}
            manual
            onFetchData={handleTableFetchData}
            page={paginationState.page}
            pages={pages}
          />
        </Grid>
      </Grid>
    </div>
  );
}
